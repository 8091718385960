@mixin letter-spacing($spacing) {
  letter-spacing: calc($spacing / 1000) * 1em;
}
.spacing-100 {
  @include letter-spacing(100);
}
.spacing-75 {
  @include letter-spacing((75));
}

