[data-block-name="copy_columns_row"] {
    .copy-columns-parent {
        @screen xl {
            // "auto-fit FITS the CURRENTLY AVAILABLE columns into the space by expanding them 
            // so that they take up any available space. The browser does that after FILLING 
            // that extra space with extra columns (as with auto-fill ) and then collapsing the empty ones."
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            // Make all rows a min of 400px tall
            grid-auto-rows: minmax(400px,max-content);
        }
    }
}