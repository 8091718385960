.image-slideshow-section {
    .tns-outer {
        width: calc(100% - 50px);
        margin: 0 auto;
    }
}

// Override of default alignment for tiny sliders (top)
[data-block-name="image_slideshow"].tns-slider {
    & > [data-block-name="image_slide"].tns-item {
        vertical-align: middle;
    }
}

[data-block-name="image_slide"] {
    .content {
        @media (hover:hover) {
            &:hover {
                .caption.desktop {
                    transform: translateY(0);
                    min-height: 100%;
                }
            }
        }
        picture {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        .caption {
            &.mobile {
                grid-row: 2/3;
            }
            &.desktop {
                grid-column: 1/2;
                grid-row: 1/2;
                transition-property: min-height, transform;
                min-height: 100%;
                &::after {
                    content: '';
                    @apply h-full w-full inset-0 absolute -z-[1];
                    @apply bg-brand opacity-75;
                }
                @media (hover:hover) {
                    min-height: 0;
                    transform: translateY(100%);
                }
            }
        }
    }
}
