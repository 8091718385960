@layer base {

  //---------------------------------------------
  // COLORS - update in Wygwam Configurations, TailwindCSS Config file!
  //-------------------- -------------------------
  :root, ::before, ::after {
    --color-body: 31, 21, 21; // rgb(31, 21, 21); //#1F1515
    --color-brand: 73, 126, 145; // rgb(73, 126, 145); // #497E91
    // --color-brand:  68, 118, 136; // #447688 // IMPORTANT: NOTE: darkened for testing!
    --color-brand-light: 99, 145, 162; // rgb(99, 145, 162); // #6391a2
    --color-brand-dark: 52, 104, 123; // rgb(52, 104, 123) //#34687b
    --color-gray: 112, 112, 112; // #707070
    --color-muted: 245, 244, 241; // #F5F4F1 
    --color-yellow: 236, 197, 57; // #ECC539
    --color-green: 93, 148, 110; // #5d946e 
    --color-light-green: 179, 221, 196; //#b3ddc4
    --color-yellow-green: 218, 228, 132; //#DAE484
    --color-red: 217, 86, 39; //#D95627
    --color-orange: 239, 147, 80; //#EF9350 
    --color-navy: 13, 65, 73; //#0D4149    

    //--------------------------------------------- 
    // From Kevin Powell: .flow class
    // Use the .flow class on a parent element where there's many children (like a parent for Wysiwyg)
    // to space each out evenly
    //---------------------------------------------
    --flow-spacer: 1.25rem;
  }

  html {
    * {
      @apply tracking-brand;
    } 
    .btn-styles {
      // @apply mx-auto md:ml-0;
      @apply rounded-3xl px-5 py-2 grid;
    }
    .btn-styles-animations {
      transition-property: transform, border-color, background-color;
      @apply duration-300;
      @apply hover:-translate-y-1 active:translate-y-0 focus:translate-y-0;
    }
    // This is in the hero header on Industry pages
    .view-all-resources-btn {
      @extend .btn-styles-animations;
    }

    .btn-brand {
      @extend .btn-styles;
      @extend .btn-styles-animations;

      @apply bg-brand text-white text-lg border-transparent border-2;
      @apply w-max;
      @apply hover:text-brand hover:bg-white hover:border-brand;
      @apply active:text-brand active:bg-white active:border-brand;
      @apply focus:text-brand focus:bg-white focus:border-brand;
    }

    // For now, I have the transition to the "brand" #497E91 color
    .btn-outline-white {
      @extend .btn-styles;
      @extend .btn-styles-animations;

      @apply text-white text-lg border-white border-2;
      @apply w-max;
      @apply hover:bg-brand hover:border-brand;
      @apply active:bg-brand-dark active:border-brand-dark;
      @apply focus:bg-brand-light focus:border-brand-light;
    }
    .btn-outline-brand {
      @extend .btn-styles;
      @extend .btn-styles-animations;

      @apply text-brand text-lg border-brand border-2;
      @apply w-max;
      @apply hover:text-white hover:bg-brand hover:border-brand;
      @apply active:text-white active:bg-brand-dark active:border-brand-dark;
      @apply focus:text-white focus:bg-brand-light focus:border-brand-light;
    }
    .btn-outline-brand-navy {
      @extend .btn-styles;
      @extend .btn-styles-animations;

      @apply text-brand-navy text-lg border-brand-navy border-2;
      @apply w-max;
      @apply hover:text-white hover:bg-brand-navy hover:border-brand-navy;
      @apply active:text-white active:bg-brand-navy active:border-brand-navy;
      @apply focus:text-white focus:bg-brand-navy focus:border-brand-navy;
    }
    .btn-outline-body {
      @extend .btn-styles;      
      @extend .btn-styles-animations;

      @apply text-body text-lg border-body border-2;
      @apply w-max;
      @apply hover:text-white hover:bg-body hover:border-body;
      @apply active:text-white active:bg-brand-dark active:border-brand-dark;
      @apply focus:text-white focus:bg-brand-light focus:border-brand-light;
    }
    .btn-outline-white-on-brand {
      @extend .btn-outline-white;      
      @extend .btn-styles-animations;

      @apply w-max;
      @apply text-lg;
      @apply hover:bg-white hover:border-white hover:text-brand;
      @apply active:bg-white active:border-white active:text-brand;
      @apply focus:bg-white focus:border-white focus:text-brand;
      @apply font-bold border-[3px]; 
    }
    .btn-outline-brand-with-arrow {
      @extend .btn-styles;      
      @extend .btn-styles-animations;

      @apply text-sm;
      @apply w-full;
      @apply grid;
      grid-template-columns: repeat(2, auto);
      @apply border-brand text-brand border-2;
      @apply hover:bg-brand hover:border-brand hover:text-white;
      @apply active:bg-brand-dark active:border-brand-dark active:text-white;
      @apply focus:bg-brand-light focus:border-brand-light focus:text-white;
      &::after {
        // Utilize URL encoder: https://yoksel.github.io/url-encoder/
        // content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.2' height='25' viewBox='0 0 17.2 25'%3E%3Cpath id='Previous_arrow' data-name='Previous arrow' d='M12.5,0,25,17.2H0Z' transform='translate(0 25) rotate(-90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
        @apply duration-300;
        justify-self: flex-end;
        line-height: 1;
      }
      &:hover {
        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        }
      }
    }
    .input-outline-brand-with-arrow {
      @extend .btn-styles;      
      // @extend .btn-styles-animations;

      @apply text-sm;
      @apply w-full;
      @apply grid;
      grid-template-columns: repeat(2, auto);
      @apply border-brand text-brand border-2;
      // @apply hover:bg-brand hover:border-brand hover:text-white;
      // @apply active:bg-brand-dark active:border-brand-dark active:text-white;
      // @apply focus:bg-brand-light focus:border-brand-light focus:text-white;
    }
    .select-wrapper {
      cursor: pointer;
      .select-brand {
        @extend .btn-styles;
        @apply grid grid-cols-2;
        @apply bg-none; // removes the default select arrow dropdown
        @apply border-brand text-brand border-2;
        @apply hover:border-brand-dark hover:text-brand-dark;
        @apply active:border-brand-light active:text-brand-light;
        @apply focus:border-brand-light focus:text-brand-light;
      }
      &::after {
        // Utilize URL encoder: https://yoksel.github.io/url-encoder/
        // content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.2' height='25' viewBox='0 0 17.2 25'%3E%3Cpath id='Previous_arrow' data-name='Previous arrow' d='M12.5,0,25,17.2H0Z' transform='translate(0 25) rotate(-90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
        position: relative;
        right: 20px;
      }
    }
  }

//---------------------------------------------
// WIDTHS
//---------------------------------------------
// w-3/12
// w-2/6
// w-6/12
// w-9/12
// w-screen

  //---------------------------------------------
  // FONTS
  //---------------------------------------------
  // Define any TailwindCSS/Forms plugin styles within the base layer
  // ** Be sure to test font imports in various browsers

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: local("Montserrat Regular"), url("/src/fonts/montserrat/Montserrat-Regular.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 400;
    src: local("Montserrat Italic"), url("/src/fonts/montserrat/Montserrat-Italic.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    src: local("Montserrat Thin"), url("/src/fonts/montserrat/Montserrat-Thin.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 100;
    src: local("Montserrat Thin Italic"), url("/src/fonts/montserrat/Montserrat-ThinItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src: local("Montserrat Light"), url("/src/fonts/montserrat/Montserrat-Light.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 300;
    src: local("Montserrat Light Italic"), url("/src/fonts/montserrat/Montserrat-LightItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: local("Montserrat Medium"), url("/src/fonts/montserrat/Montserrat-Medium.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    src: local("Montserrat Medium Italic"), url("/src/fonts/montserrat/Montserrat-MediumItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    src: local("Montserrat SemiBold"), url("/src/fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 600;
    src: local("Montserrat SemiBold Italic"), url("/src/fonts/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: local("Montserrat Bold"), url("/src/fonts/montserrat/Montserrat-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    src: local("Montserrat Bold Italic"), url("/src/fonts/montserrat/Montserrat-BoldItalic.woff") format("woff");
  }
}

//---------------------------------------------
// PREFERS REDUCED MOTION
//---------------------------------------------
@media screen and (prefers-reduced-motion: reduce){
  *:not(#cover) {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition: none !important;
    scroll-behavior: auto !important;
  }
}

//---------------------------------------------
// FILTERS
//---------------------------------------------
// Filters SVG from black to brand #497E91
$brandFilter: brightness(0) saturate(100%) invert(48%) sepia(44%) saturate(411%) hue-rotate(150deg) brightness(85%) contrast(88%);
 //#6391a2
$brandLightFilter:brightness(0) saturate(100%) invert(54%) sepia(26%) saturate(483%) hue-rotate(150deg) brightness(94%) contrast(91%);
//#34687b
$brandDarkFilter:brightness(0) saturate(100%) invert(36%) sepia(14%) saturate(1468%) hue-rotate(150deg) brightness(96%) contrast(91%);

a, button {
  @apply cursor-pointer;
  .filter-brand {
    transition-property: filter;
    @apply duration-300;
    filter: $brandFilter;
    &:hover {
      filter: $brandLightFilter;
    }
    &:active, &:focus { 
      filter: $brandDarkFilter;
    }
  }
  &:not([class]) {
    color: #{"rgb(var(--color-brand))"};
    &:hover, &:focus, &:focus-visible, &:active {
      text-decoration: underline;
    }
  }
}

  //---------------------------------------------
  // From Kevin Powell: .flow class
  // Use the .flow class on a parent element where there's many children (like a parent for Wysiwyg)
  // to space each out evenly
  //---------------------------------------------
.flow > *:where(:not(:first-child)) {
  margin-top: var(--flow-spacer, 1rem);
}

//---------------------------------------------
// This is to blur the page's contents when the overlay
// menu has been opened
//---------------------------------------------
[role="main"] {
  @apply duration-300;
  &.blurred {
    filter: blur(2px);
  }
} 

//---------------------------------------------
// Unordered and ordered list default styles
//---------------------------------------------
ul:not(.accordion):not(.accordion-collapse):not(.accordion-filter-list):not([role="navigation"]):not(.list-check):not(.list-results):not(.list-filters):not([role="listbox"]):not(.errors.help-block):not(.product-badges),
ol:not(.accordion):not(.accordion-collapse):not(.accordion-filter-list):not([role="navigation"]):not(.list-check):not(.list-results):not(.list-filters):not([role="listbox"]):not(.errors.help-block):not(.carousel__dots) { 
  @apply ml-4;
  @apply list-disc list-outside;

  // The below 3 properties help for when a <ul> is broken up into two columns when content
  // is very tall; finicky in Firefox
  page-break-inside: avoid;           
  break-inside: avoid-column;         
  display: table;                     
  li {
      @apply pl-6;
      &::before, &::marker {
          color: #{"rgb(var(--color-brand))"};
      }
      &:not(:last-child) {
          margin-bottom: 0.75rem;
      } 
  }
  &.white-markers {
    li {
      &::before, &::marker {
        color: white;
      }
    }
  }
}

ul.product-badges {
  margin-left: 0;
  li {
    @apply pl-0;
    padding: 0 0;
    padding-left: 0;
  }
}
//---------------------------------------------
// Checkmark list styles
//---------------------------------------------
ul.list-check, ol.list-check {
  // @apply ml-4;
  @apply list-outside;
  li {
    @apply text-lg;
    @apply pl-6 m-0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAyNCAyNCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBkPSJNIDIwLjI5Mjk2OSA1LjI5Mjk2ODggTCA5IDE2LjU4NTkzOCBMIDQuNzA3MDMxMiAxMi4yOTI5NjkgTCAzLjI5Mjk2ODggMTMuNzA3MDMxIEwgOSAxOS40MTQwNjIgTCAyMS43MDcwMzEgNi43MDcwMzEyIEwgMjAuMjkyOTY5IDUuMjkyOTY4OCB6Ij48L3BhdGg+PC9zdmc+);
    background-size: 18px;
    background-position: 0 5px; // this adjusts check into correct alignment
    background-repeat: no-repeat;
  }
}

//---------------------------------------------
// When hovering over linked images (so an img within an <a>)
//---------------------------------------------
:is(a.grow-on-hover) img {
  @screen md {
    @apply duration-300 transition-transform;
    &:hover {
      @apply scale-110;
    }
  }
}

