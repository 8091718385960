#news-search-results {
    // &::before {
    //     content: '';
    //     @apply bg-brand-muted left-0 right-0 bottom-0 h-full w-full absolute -z-[1];
    // }
    .news-search-results-grid {
        .news-result-item {
            @media lg {
                aspect-ratio: 3/2;
            }
            &:first-child {
                @apply relative;
                &::after {
                    content: "";
                    position: absolute;
                    left: -100vw;
                    right: -100vw;
                    top: -50%;
                    z-index: -1;
                    height: 100%;
                    width: 200vw;
                    background-color: white;
                }
            }
            picture {
                grid-column: 1/2;
                grid-row: 1/2; 
            }
            .news-result-item-content {
                grid-column: 1/2;
                grid-row: 1/2;
                &::before {
                    content: '';
                    @apply h-full w-full inset-0 absolute -z-0;
                    @apply opacity-75;
                    background-color: theme('colors.brand.DEFAULT'); 
                }
                &.title-container {
                    top: 0%;
                    bottom: unset;
                }
                &.details-container {
                    top: 100%;
                    bottom: unset;
                }
            }
            @media (hover: hover) {
                .news-result-item-content {
                    transition-property: top, bottom;
                    &.title-container {
                        display: grid;
                    }
                    &.details-container {
                        .news-item-title {
                            display: none;
                        }
                    }
                }
                &:hover {
                    .news-result-item-content {
                        &.title-container {
                            bottom: 100%;
                            top: -100%;
                        }
                        &.details-container {
                            top: 0%;
                            bottom: unset; 
                        }
                    }
                }
            }
            @media (hover: none) {
                .news-result-item-content {
                    &.title-container {
                        display: none;
                    }
                    &.details-container {
                        top: 0%;
                    }
                }
            }
        }
    
    }
}
