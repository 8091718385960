  [data-block-name="block_links"] {
    .block-links-parent {
        // "auto-fit FITS the CURRENTLY AVAILABLE columns into the space by expanding them 
        // so that they take up any available space. The browser does that after FILLING 
        // that extra space with extra columns (as with auto-fill ) and then collapsing the empty ones."
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: minmax(300px, max-content);
        @screen lg {
            grid-auto-rows: 300px;
            grid-template-columns: repeat(3, 300px);
        }
    }
    [data-block-name="block_link"] {
        picture {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        .page-title {
            grid-column: 1/2;
            grid-row: 1/2;
            &::after {
                content: '';
                @apply h-full w-full inset-0 absolute -z-[1];
                @apply bg-brand opacity-75;
            }
        }
        @media (hover:hover) {
            &:hover {
                picture {
                    img {
                        transform: scale(1.1);
                    }
                }
                .page-title {
                    opacity: 0;
                }
            }
        }
    }
  }