//---------------------------------------------
// If there's an image on the left with a list in the copy
// on the right, pad the ul so that the bullets don't touch
// the edge of the image.

// ** Typically just on News Detail pages
//---------------------------------------------
.floating-left-img ~ ul {
    @screen lg {
        padding-left: 1rem;
    }
}