.product-info-accordion {
    ul, ol {
        @apply list-outside list-disc;
        li {
            &:not(:last-child) {
                margin-bottom: auto;
            }
        }
    }
}
