// Accordion styles from accordion-js module
@import 'accordion-js/dist/accordion.min.css';


@import "tailwindcss/base";
// Includes:
//  Adobe Font Definitions, downloaded font definitions
@import "./custom-base-styles.scss";

@import "tailwindcss/components";
// Navigation, tabbing, accordions,
@import "./custom-components.scss";
// Overriding third party & theme styles
@import "./custom-overrides.scss";
// Basic slideshow...
@import "../../node_modules/tiny-slider/dist/tiny-slider.css";
// Fancyapps - Carousel and Fancybox enabled
@import "../../node_modules/@fancyapps/ui/dist/fancybox.css";
// Lite Youtube styles
@import "../../node_modules/lite-youtube-embed/src/lite-yt-embed.css";
// NOTE: Lite Vimeo styles are built in via the inline element embed
// via <styles> element

 
@import "tailwindcss/utilities";
// Letter spacing utility... 
@import "./custom-utilities.scss";

//---------------------------------------------
// Hero Banner
//---------------------------------------------
@import "./page-sections/hero-banner-bloq.scss";

//---------------------------------------------
// Page Sections, Bloqs
//---------------------------------------------
@import "./page-sections/quick-links-bloq.scss";
@import "./page-sections/block-links-bloq.scss";
@import "./page-sections/featured-posts-bloq.scss";
@import "./page-sections/layered-images-with-copy.scss";
@import "./page-sections/image-with-copy.scss";
@import "./page-sections/floated-image-with-copy.scss";
@import "./page-sections/image-slideshow.scss";
@import "./page-sections/linkable-image-slideshow.scss";
@import "./page-sections/centered-copy-row.scss";
@import "./page-sections/copy-row.scss";
@import "./page-sections/video-feature-row-with-intro-copy.scss";
@import "./page-sections/video-row.scss";
@import "./page-sections/copy-column-row-bloq.scss";


//---------------------------------------------
// Industry page
//--------------------------------------------- 
@import "./industry/product-results-grid.scss";
@import "./industry/featured-products-and-featured-news.scss";

//---------------------------------------------
// Resources page
//--------------------------------------------- 
@import "./resources/resources-search-results.scss";

//---------------------------------------------
// Product page 
//---------------------------------------------
@import "./products/product-hero-banner.scss";
@import "./products/product-accordions.scss";
@import "./products/related-products.scss";

//---------------------------------------------
// Contact page
//---------------------------------------------
@import './contact/contact-page.scss';

//---------------------------------------------
// News Landing page
//---------------------------------------------
@import './news/news-search-results-grid.scss';
@import './news/news-detail.scss';

//---------------------------------------------
// Search Results
//---------------------------------------------
@import './search-results/search-results.scss';

//---------------------------------------------
// Find a Rep
//---------------------------------------------
@import './find-a-rep/find-a-rep-results.scss';



@import "./layout/footer.scss";
@import "./layout/nav.scss";
