.list-results {
    & > li {
        padding: .5rem 1rem;
        &:last-child {
            border-bottom: 1px solid rgb(var(--color-brand)) !important;
        }
        a {
            flex: 0 0 auto;
            img {
                @extend .btn-styles-animations;
            }
        }
    }
}