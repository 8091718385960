footer {
    :first-child {
        grid-template-columns: auto auto max-content;
    grid-template-areas: "logo . social"
                        "logo . social"
                        "contact contact social"
                        "contact contact social"
                        "newsletter newsletter newsletter"
                        "legal legal legal";
    @screen lg {
        grid-template-areas: "logo . newsletter"
                            "contact . social"
                            "contact . legal";
    }
    #footer-logo {
        grid-area: logo;
    }
    #footer-contact-info {
        grid-area: contact;
        #address {
            grid-template-columns: repeat(2, max-content);
        }
    }
    #footer-social-links {
        grid-area: social;
    }
    #newsletter-signup {
        grid-area: newsletter;
    }
    #legal-links {
        grid-area: legal;
    }
    .accordion#legal-links {
        .accordion-button {
            .toggle-open-symbol {
                // *** The SVG image is defined at 20px x 20px
                filter: $brandFilter;
                &::after {
                    filter: $brandFilter;
                    @apply transition-transform;
                    content: "";
                    height: 2px;
                    width: 10px;
                    display: grid;
                    background-color: black;
                    position: absolute;
                    top: 1px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    transform: rotate(90deg);
                    border-radius: 50px;
                }
            }
            &[aria-expanded="true"] {
                .toggle-open-symbol {
                    &::after {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
    }
}