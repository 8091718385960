// NOTE: SEPARATED XL/2XL grid layouts below this grouping:
[data-block-name="hero_banner_bloq"], 
.hero-image-section {
  grid-template-columns: 1fr;
  @screen lg {
    grid-template-columns: auto;
    grid-template-rows: 1fr;
  }
  // News Post pages, Legal pages  
  &.has-dark-overlay {
    &::before {
      content: '';
      @apply absolute inset-0 z-[1] w-full h-full;
      background-color: black;
      opacity: var(--dark-overlay-opacity, 25%);
    }
    // The dark overlay on images in two column layouts
    // only applies at large & up
    &.two-col {
      &::before {
        content: none;
        @screen lg {
          content: '';
          @apply absolute inset-0 z-[1] w-1/2 h-full;
          background-color: black;
          opacity: var(--dark-overlay-opacity, 25%);
        }
      }
    }
  }
  &.push-copy-down {
    .hero-banner-copy-container {
      @apply pt-32 pb-12;
      @screen lg {
        @apply pt-20 pb-0;
      }
    }
  }
  .line-divider {
    border-radius: 50px; // to create rounded corners
  }
  .hero-banner-video {
    grid-column: 1/2;
    grid-row: 1/3;
    @screen lg {
      grid-column: 1/3;
      grid-row: 1/2;
    }
  }
  .hero-banner-image {
    grid-column: 1/2;
    grid-row: 1/3;
    @screen lg {
      grid-column: 1/3;
      grid-row: 1/2;
    }
  }
  .hero-banner-copy-container {
    grid-column: 1/2;
    grid-row: 1/3;
    @screen lg {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }
  #hero-banner-circles {
    grid-column: 1/2;
    grid-row: 2/3;
    @screen lg {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  &.two-col {
    @screen lg {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
    &.video {
      .hero-banner-video {
        grid-column: 1/2;
        grid-row: 1/2;
        @screen lg {
          grid-column: 1/2;
          grid-row: 1/2;
        }
      }
      .hero-banner-copy-container {
        grid-column: 1/2;
        grid-row: 2/3;
        @screen lg {
          grid-column: 2/3;
          grid-row: 1/2;
        }
      }
    }
    .hero-banner-copy-container {
      grid-column: 1/2;
      grid-row: 2/3;
      @screen lg {
        grid-column: 2/3;
        grid-row: 1/2;
      }
    }
    .hero-banner-image {
      grid-column: 1/2;
      grid-row: 1/2;
      @screen lg {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }
  }
}

[data-block-name="hero_banner_bloq"] {
  @screen xl {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
.hero-image-section {
  @screen 2xl {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

