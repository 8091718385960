[data-block-name="layered_images_with_copy"] {
    .images-wrapper{ 
        display: grid;
        height: 515px;
        grid-template-rows: 1fr 50px 1fr;
        grid-template-columns: .36fr .50fr .14fr;  
        @screen lg {
            height: auto;
            min-width: 450px;
        }
        @screen xl {
            min-width: 630px;
        }
        [data-block-name="layered_images_with_copy_image_item"] {
            overflow: hidden;
            &:first-child {
                height: 240px;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
                &::before {
                    content: '';
                    @apply bg-brand;
                    height: 2px;
                    width: 85px;
                    position: absolute;
                    top: -18px;
                    right: 0;
                }
                @screen lg {
                    min-height: 250px;
                    height: auto;
                    &::before {
                        width: 120px;
                        top: -25px;
                    }
                }
                @screen xl {
                    min-height: 350px;
                }
            }
            &:nth-child(2) {
                height: 300px; 
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 4;
                &::before {
                    content: '';
                    @apply bg-brand; 
                    height: 90px;
                    width: 2px;
                    position: absolute; 
                    bottom: 0;
                    left: -20px;
                }
                @screen lg {
                    min-height: 340px;
                    height: auto;
                    &::before {
                        height: 130px;
                        left: -35px;
                    }
                }
                @screen xl {
                    min-height: 440px;
                }
            }
        }
    }
    [data-block-name="layered_images_with_copy_copy"] {
        h2 {
            @apply mb-8 lg:mb-0;
        }
        @screen lg {
            &.vertical-centered-accent {
                p {
                    &:first-of-type {
                        &::before {
                            content: '';
                            @apply bg-brand;
                            height: 100px;
                            width: 2px;
                            display: block;
                            margin: 30px auto;
                            border-radius: 50px; // to create rounded corners
                            //    NOTE: the --flow-spacer var spaces out content nicely, so adjusting this margin
                            //    for that space so that there's not an add'l --flow-spacer 'px' in margin between p and line detail
                            margin-top: calc(30px - var(--flow-spacer));
                        }
                    }
                }
            }
            &.horizontal-left-accent {
                p {
                    &:first-of-type {
                        &::before {
                            content: '';
                            @apply bg-brand;
                            height: 2px;
                            width: 100px;
                            display: block;
                            margin: 30px 0;
                            border-radius: 50px; // to create rounded corners
                            //    NOTE: the --flow-spacer var spaces out content nicely, so adjusting this margin
                            //    for that space so that there's not an add'l --flow-spacer 'px' in margin between p and line detail
                            margin-top: calc(30px - var(--flow-spacer));
                        }
                    }
                }
            }
        }
    }
}