//---------------------------------------------
// KEYFRAME ANIMATIONS
// -Utilized in the modal dialog transitions
//---------------------------------------------
// @keyframes fadein {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// @keyframes fadeout {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }

// @keyframes slidein {
//   from {
//     transform: translateY(-100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }

// @keyframes slideout {
//   from {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateY(-100%);
//     opacity: 0;
//   }
// }

// @keyframes slideoutheight {
//   from {
//     max-height: 15rem;
//     opacity: 1;
//   }
//   to {
//     max-height: 0;
//     opacity: 0;
//   }
// }

//---------------------------------------------
// Accordion
//---------------------------------------------
.accordion-button {
  .toggle-open-symbol {
      // NOTE: 
      // *** The SVG image is defined at 15px x 15px
      // *** for the accordions on the Product filters on Market pages
      // (the text for button is text-sm)

      // *** The SVG image is defined at 22px x 22px
      // *** for the accordions on the Product detail pages 
      // (the text for the button is text-xl)

      // *** The SVG image is defined at 22px x 22px
      // *** for the Legal accordion in the footer
      // (the text for the button is text-base)

      // ^^ NOTE: Each of these has corresponding height, width, top properties
      // for the SVG based on text and button size and positioning!

      &::after {
          @apply transition-transform;
          content: "";
          display: grid;
          background-color: black;
          position: absolute;
          top: 2px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          transform: rotate(90deg);
          border-radius: 50px; // to create rounded corners
      }
  }
  &[aria-expanded="true"] {
      .toggle-open-symbol {
          &::after {
              transform: rotate(0deg);
          }
      }
  }
}
.accordion-collapse {
  transition: height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

// This assumes that the text on panel buttons is 
// text-xl and that the svg icon is 20x20px
.accordion.basic.brand-blue {
  .accordion-button {
      .toggle-open-symbol {
          // *** The SVG image is defined at 20px x 20px
          filter: $brandFilter;
          &::after {
              filter: $brandFilter;
              height: 2px;
              width: 10px;
              top: 1px;
              border-radius: 50px; // to create rounded corners
          }
      }
  }
}

//---------------------------------------------
// Tabs
// NOTE - includes gentle fade transition between tab contents
//---------------------------------------------
// [role="tablist"] {
//   [role="tab"] {
//     @apply bg-red-400 bg-opacity-90;
//     @apply px-8 py-4;

//     &:hover {
//       @apply bg-opacity-80;
//     }
//     &.active {
//       @apply bg-opacity-100;
//     }
//   }
// }
// [role="tabpanel"] {
//   display: none;
// }
// .active-panel-container {
//   display: grid;
// }
// [role="tabpanel"],
// .active-panel-container {
//   @apply bg-red-400;
//   @apply p-5;
//   & > .grid {
//     @apply duration-500;
//     @apply container;
//   }
// }

//---------------------------------------------
// Modal
//---------------------------------------------
// .modal {
//   opacity: 0;
//   padding: 1em;
//   & > * {
//     margin: 0 0 0.5rem 0;
//   }
//   &[open] {
//     opacity: 1;
//     animation: slidein 0.5s forwards;
//     &.close {
//       animation: slideout 0.5s forwards;
//     }
//   }
//   ::backdrop {
//     background: rgb(0 0 0 / 0.8);
//     animation: fadein 0.5s forwards;
//     &.close {
//       animation: fadeout 0.5s forwards;
//     }
//   }
// }

//---------------------------------------------
// Tiny Slider (Slideshow) DEFAULTS
//---------------------------------------------
.tns-nav {
  text-align: center;
  & > [aria-controls] {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ddd;
    border: 0;
  }
  & > .tns-nav-active {
    background-color: #999;
  }
}

//---------------------------------------------
// Fancyapps Slider (Carousel) with Lightbox (Fancybox)
// ** this is a copy/paste from the Showcase page
//---------------------------------------------
#mainCarousel {
  width: 600px;
  margin: 0 auto 1rem auto;

  --carousel-button-color: #170724;
  --carousel-button-bg: #fff;
  --carousel-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

  --carousel-button-svg-width: 20px;
  --carousel-button-svg-height: 20px;
  --carousel-button-svg-stroke-width: 2.5;
  .carousel__slide {
    width: 100%;
    padding: 0;
  }
  .carousel__button {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 4px #a78bfa;
    }
    &.is-prev {
      left: -1.5rem;
    }
    &.is-next {
      right: -1.5rem;
    }
  }
}

#thumbCarousel {
  .carousel__slide {
    opacity: 0.5;
    padding: 0;
    margin: 0.25rem;
    width: 96px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    &.is-nav-selected {
      opacity: 1;
    }
  }
}

//---------------------------------------------
// Cookie consent message
//---------------------------------------------
// #notice-message {
//   display: none;
//   &.open {
//     opacity: 1;
//     display: block;
//   }
//   &.close {
//     animation: slideoutheight 0.5s forwards;
//   }
//   .container {
//     grid-template-columns: 1fr auto 1fr;
//     p:nth-child(1) {
//       grid-column-start: 2;
//     }
//     button {
//       margin-left: auto;
//     }
//   }
// }

//---------------------------------------------
// Cookie consent form
//---------------------------------------------
// #cookieConsentForm {
//   &.close {
//     animation: slideoutheight 0.5s forwards;
//   }
// }

//---------------------------------------------
// Freeform Form OVERRIDES of exp:freeform_form:render styles
// that are auto-included with those template tags
//---------------------------------------------
// form {
//   .freeform-row {
//     margin: 0;
//     .freeform-column {
//       .freeform-label {
//         font-weight: normal;
//         &.freeform-required {
//           &::after {
//             @apply text-red-500;
//           }
//         }
//       }
//     }
//   }
// }

//---------------------------------------------
// Prevent body/page scrolling when overlay menu is opened;
// overflow hidden works on desktops, but iOS doesn't behave
//---------------------------------------------
.noscroll {
 overflow: hidden;
 position: fixed;
 inset-inline: 0;
}
