// This allows for the nav-arrow controls to be clickable by alleviating space on either side of
// slideshow container (arrows about 25px each)
.linkable-image-slideshow-section {
    .tns-outer {
        width: calc(100% - 50px);
        margin: 0 auto;
        @screen lg {
            width: auto;
        }
        // Override of default alignment for tiny sliders (top)
        [data-block-name="linkable_image_slideshow"]{
            &.tns-horizontal.tns-subpixel>.tns-item {
                vertical-align: middle;
            }
        }
    }
}