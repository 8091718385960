.featured-posts-container {
    [data-block-name="featured_posts"] {
        @screen lg {
            &::after {
                content: "";
                @apply absolute left-0 right-0 bottom-0 top-1/3 w-full bg-brand -z-10;
            }
        }
        [data-block-name="featured_post"] {
            &::after {
                content: "";
                @apply absolute left-0 right-0 bottom-0 w-full bg-brand -z-10;
                top: 20%;
                @screen lg {
                    @apply top-1/3;
                }
                @screen lg {
                    content: none;
                }
            }
        }
    }
}