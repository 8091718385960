[data-block-name="image_with_copy"] {
    [data-block-name="image_with_copy_image_item"] {
        margin-top: 25px; // to account for line detail in ::before
        &::before {
            position: relative;
            content: '';
            @apply bg-brand;
            width: 100px;
            height: 2px;
            display: block;
            top: -25px;
            margin-left: auto;
            border-radius: 50px; // to create rounded corners
        }
        @screen lg {
            margin-top: 0;
            &::before {
                content: '';
                @apply bg-brand;
                width: 100px;
                height: 2px;
                display: block;
                margin-left: auto;
                top: calc(100% + 25px);
            }
        }
    }
    [data-block-name="image_with_copy_copy"] {
        &.vertical-centered-accent {
            p {
                &:first-of-type {
                    &::before {
                        content: '';
                        @apply bg-brand;
                        height: 100px;
                        width: 2px;
                        display: block;
                        margin: 30px auto;
                        border-radius: 50px; // to create rounded corners
                    //    NOTE: the --flow-spacer var spaces out content nicely, so adjusting this margin
                    //    for that space so that there's not an add'l --flow-spacer 'px' in margin between p and line detail
                        margin-top: calc(30px - var(--flow-spacer));
                    }
                    &.text-white {
                        &::before {
                            @apply bg-white;
                        }
                    }
                }
            }
        }
        &.horizontal-left-accent {
            p {
                &:first-of-type {
                    &::before {
                        content: '';
                        @apply bg-brand;
                        height: 2px;
                        width: 100px;
                        display: block;
                        margin: 30px auto; 
                        border-radius: 50px; // to create rounded corners
                        @screen lg {
                            margin: 30px 0;
                            // NOTE: the --flow-spacer var spaces out content nicely, so adjusting this margin
                            // for that space so that there's not an add'l --flow-spacer 'px' in margin between p and line detail
                            margin-top: calc(30px - var(--flow-spacer));
                        }
                    }
                    &.text-white {
                        &::before {
                            @apply bg-white;
                        }
                    }
                }
            }
        }
    }
}