//---------------------------------------------
// Overriding third-party and theming styles
//---------------------------------------------

  //---------------------------------------------
  // Freeform Pro responsive styling of columns overrides
  // Specific to the Contact Form (city, state, zip)
  //---------------------------------------------
  form {
    .freeform-row {
      .freeform-column { 
        &.has-error {
          input, textarea, select {
            @apply #{border-red-900 !important}; 
            border: 2px solid;
          }
        }
      }
      .freeform-column-4 {
        width: 100%;
        @screen md {
          @apply w-1/3;
        }
      }
    }
  }

  //---------------------------------------------
  // Override of Tailwind Forms Plugin form-input styles
  //---------------------------------------------
  [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], 
  [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], 
  [type='time'], [type='week'], [multiple], textarea, select { 
    --tw-ring-color: #{"rgb(var(--color-brand))"};
    --tw-outline-color: #{"rgb(var(--color-brand))"}; 
    outline-color: #{"rgb(var(--color-brand))"};
    border-color: transparent;
    border-width: 0;
    padding: 0;
    background-color: transparent;
    line-height: initial;
    font-size: inherit;
    &:focus, &:focus-visible, &:focus-within {
      --tw-ring-color: #{"rgb(var(--color-brand))"};
      --tw-ring-offset-width: 3px;
      outline-offset: 3px;
    }
  }

  //---------------------------------------------
  // Restyling the 'clear input' X on inputs
  //---------------------------------------------
  input[type="search"] {
    &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.965' height='15.965' viewBox='0 0 15.965 15.965'%3E%3Cg id='Group_663' data-name='Group 663' transform='translate(1.414 1.414)'%3E%3Cline id='Line_45' data-name='Line 45' x2='13.136' y2='13.136' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Line_46' data-name='Line 46' x1='13.136' y2='13.136' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");  
    background-size: 10px 10px;
    &:hover {
      cursor: pointer;
    }}
    &.text-brand {
      filter: $brandFilter;
      &::-webkit-search-cancel-button {      
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.965' height='15.965' viewBox='0 0 15.965 15.965'%3E%3Cg id='Group_663' data-name='Group 663' transform='translate(1.414 1.414)'%3E%3Cline id='Line_45' data-name='Line 45' x2='13.136' y2='13.136' fill='none' stroke='%23497E91' stroke-linecap='round' stroke-width='2'/%3E%3Cline id='Line_46' data-name='Line 46' x1='13.136' y2='13.136' fill='none' stroke='%23497E91' stroke-linecap='round' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A") !important;  
      }    
    }
  }

//---------------------------------------------
// Tiny Sliders:
// This is to allocate space for the box-shadow to display on the top of the Featured Products,
// Related Products, etc.
//---------------------------------------------  
.tns-ovh {
  padding-top: 25px !important;
  margin-top: -25px !important;
}

//---------------------------------------------
// Can't extend the 'position' properties in TailwindCSS...
// so I made a class specifically for the anchor nav bar responsiveness
//---------------------------------------------
.lg-position-initial {
  @screen lg {
    position: initial;
  }
}

//---------------------------------------------
// See the break-section-content-into-columns.js functions;
// Removes the accent line from out in front of the first p
// tag when the content is wrapped into two columns and the
// heading is instead appended outside of the new column layout
//---------------------------------------------
.newly-wrapped-content {
  column-count: 1;
  @screen lg {
    column-count: 2;
  }
  p {
    &:first-child {
      &::before {
        content: none !important;
      }
    }
  }
}
.append-new-accent-here {
  &::after {
    content: '';
    @apply bg-brand;
    height: 2px;
    width: 100px;
    display: block;
    margin: 30px 0;
    // This has to be the BOTTOM margin as opposed to top now that it's applied to the heading
    // instead of the paragraph p tag
    margin-bottom: calc(30px - var(--flow-spacer));
  }
}

//---------------------------------------------
// Override Accordion JS styles
//---------------------------------------------
.accordion-container {
  .ac {
    margin-top: unset;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    .ac-header {
      margin: unset;
      padding: unset;
      .ac-trigger {
        font: inherit;
        color: initial;
        text-align: left;
        width: 100%;
        padding: unset;
        display: block;
        cursor: pointer;
        background-color: transparent;
        transition: color 0.25s ease;
        position: relative;
        text-decoration: none;
        margin: unset;
        // border: unset;
        &:focus, &:focus-visible {
          color: unset;
        }
      }
    }
    .ac-panel {
      .ac-text {
        font: inherit;
        color: unset;
        padding: unset;
        margin: unset;
      }
    }
  }
}