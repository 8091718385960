:root {
    // Defining the vars for the Fancybox and Carousel slides
    --carousel-slide-width: 100%;
    --carousel-button-shadow: 0;
    --fancybox-accent-color: rgb(var(--color-brand));
    --fancybox-thumbs-border-radius: 0;

    // Need this var defined in root as well as this container
    // (this one for Carousel, below for Fancybox)
    --carousel-button-svg-stroke-width: 0;
}

// Need this var defined in this container as well as root
// (this one for the Fancybox, the above for the Carousel)
// Must be designed this specifically bc of toolbar options being
// impacted as well
.fancybox__container {
    .carousel__button {
        &.is-prev, &.is-next {

            --carousel-button-svg-stroke-width: 0;
        }
    }
}

.product-hero {
    @screen lg {
        grid-template-columns: .55fr .45fr;
        grid-template-areas: "slideshow categories"
                             "slideshow title"
                             "slideshow description"
                             "slideshow description";
        .product-cats {
            grid-area: categories;
        }
        .product-title, .product-desc {
            grid-area: title;
        }
        // .product-hero-slideshow {
        //     grid-area: slideshow;
        // }
        .carousel-holder {
            grid-area: slideshow;
            overflow: hidden;
        }
        .product-desc {
            grid-area: description;
        }
    }
    .product-cats {
        // a {
        //     &::after {
        //         content: '•';
        //         margin: 0 5px;
        //         display: inline-block;
        //         position: relative;
        //     }
        //     &:last-child {
        //         &::after {
        //             content: '';
        //         }
        //     }
        // }
    }
    .product-title {
        grid-auto-rows: min-content;
        #product-sku {
            &::after {
                content: '';
                display: block;
                width: 100px;
                background-color: white;
                height: 4px;
                margin: 45px auto;
                border-radius: 50px; // to create rounded corners
                @screen lg {
                    margin-top: 45px;
                    margin-bottom: 45px;
                    margin-left: 0;
                }
            }
        }
    }
    // Overriding the default list styling sitewide (in the custom-base-styles.scss file)
    .product-desc {
        ul, ol {
            li {
                &::marker, &::before {
                    color:white;
                }
            }
        }
    }

    //---------------------------------------------
    // ** Revised Product Hero Slideshow/Carousel to use Fancyapps Carousel
    // instead of tinyslider for gallery/zoom functionality
    //---------------------------------------------
    #productHeroCarousel {
        margin: 0;
        .carousel__viewport {
            height: 100%;
            .carousel__track {
                height: 100%;
                .carousel__slide {
                    padding: 0;
                }
            }
        }
        .carousel__nav {
            .carousel__button {
                svg {
                    // stroke: none;
                }
            }
        }
        .carousel__dots {
            top: auto;
            left: 0;
            right: 0;
            bottom: 30px;
            @apply text-brand;
            @screen lg {
                bottom: 15px;
                right: 30px;
                left: auto;
            }
        }
    }
    // Previously built version of Product Hero Slideshow/Carousel
    // using tinyslider 
    /* 
    .product-hero-slideshow {
        .tns-nav {
            padding-bottom: 30px;
            & > [aria-controls] {
                @apply bg-brand opacity-50;
            }
            & > .tns-nav-active {
                @apply bg-brand opacity-100;
            }
            @screen lg {
                padding-bottom: 0;
                position: absolute;
                bottom: 15px;
                right: 30px;
            }
        }
    } 
    */
} 
