// NOTE: Heights of child bloqs images & associated content margins align images, text to center @xl
.featured-products-and-featured-news {
    @screen xl {
        &::after { 
            content: "";
            @apply absolute left-0 right-0 bottom-0 top-1/3 w-full bg-brand -z-10;
        }
    }
    .featured-products-and-featured-news-content {
        @screen xl {
            grid-template-columns: .4fr .6fr;
        }
        .lg_background-after-holder {
            @screen lg {
                &::after { 
                    content: "";
                    @apply absolute left-0 right-0 bottom-0 top-1/3 w-full bg-brand -z-10;
                    @screen xl {
                        content: none;
                    }
                }
            }
            // Disable/reset the .lg:container class at xl
            @screen xl {
                & > div {
                    margin: 0;
                    padding: 0;
                    max-width: unset;
                    width: unset;
                    // Heights of child bloqs images & associated content margins align images, text to center @xl
                    @apply xl:mt-[25px];
                }
            }
        }
        // Individual to featured products
        [data-block-name="featured_products_and_featured_news_products"] {
            @screen md {
                &::after { 
                    @apply top-[45%];
                }
            }
            .featured-product {
                &::after {
                    @apply top-2/4;
                }
            }
        }
        // Individual to news post
        [data-block-name="featured_products_and_featured_news_news_post"] {
            @screen md {
                &::after { 
                    @apply top-1/3;
                }
            }
            .featured-news-post {
                &::after {
                   top: 15%;
                }
            }
        }
        // *** These are shared ***
        [data-block-name="featured_products_and_featured_news_products"], [data-block-name="featured_products_and_featured_news_news_post"] {
            @screen md {
                &::after { 
                    content: "";
                    @apply absolute left-0 right-0 bottom-0 w-full bg-brand -z-10;
                    @screen xl {
                        content: none;
                    }
                }
            }
            .featured-product, .featured-news-post {
                &::after { 
                    content: "";
                    @apply absolute left-0 right-0 bottom-0 w-full bg-brand -z-10;
                    @screen md {
                        content: none;
                    }
                }
            }
        }
    }
}