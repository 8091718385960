[data-block-name="video_feature_row_with_intro_copy"] {
    [data-block-name="video_feature_row_copy"] {
        // Only change the vertical detail if they're using bg-brand on row
        .change-accent-color-white {
            p {
                &:first-of-type {
                    &::before {
                        @apply bg-white;
                    }
                }
            }
        }
        p {
            &:first-of-type { 
                &::before {
                    content: '';
                    @apply bg-brand;
                    height: 2px;
                    width: 100px;
                    display: block;
                    margin: 30px auto;
                    border-radius: 50px; // to create rounded corners
                    //    NOTE: the --flow-spacer var spaces out content nicely, so adjusting this margin
                    //    for that space so that there's not an add'l --flow-spacer 'px' in margin between p and line detail
                    margin-top: calc(30px - var(--flow-spacer));
                }
            }
        }
    }
    [data-block-name="video_feature_row_video"] {
        @screen lg {
            &::after {
                content: '';
                @apply bg-brand;
                width: 100%;
                height: calc(50% + 7rem); // This aligns perfectly with the py-14 lg:py-28 on the parent row
                top: 50%;
                left: 0;
                right: 0;
                position: absolute;
                z-index: 0;
                border-radius: 50px; // to create rounded corners
            }
        }
        lite-youtube {
            &::before {
                content: none;
            }
        }
    }
}