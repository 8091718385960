[data-block-name="quick_link"] {
    picture {
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .page-title {
        grid-column: 1/2;
        grid-row: 1/2;
        transition-property: min-height;
        &::after {
            content: '';
            @apply h-full w-full inset-0 absolute -z-[1];
            @apply opacity-75;
        }
    }
    // &:nth-child(1) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-navy;
    //         }
    //     }
    // }
    // &:nth-child(2) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-orange;
    //         }
    //     }
    // }
    // &:nth-child(3) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-red;
    //         }
    //     }
    // }
    // &:nth-child(4) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-green;
    //         }
    //     }
    // }
    // &:nth-child(5) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-lightGreen;
    //         }
    //     }
    // }
    // &:nth-child(6) {
    //     .page-title {
    //         &::after {
    //             @apply bg-brand-yellow;
    //         }
    //     }
    // }
    &:hover {
        .page-title {
            min-height: 100%;
        }
    }
}