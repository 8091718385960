address {
    .location-title {
        &::after {
            content: '';
            height: 2px;
            @apply bg-brand;
            width: 100%;
            position: relative;
            display: block;
            margin: 1rem 0;
        }
    }
}

.ajax-form {
    input, select {
        @apply bg-white border-none rounded-full px-4 py-2 text-body text-lg font-normal;
        &::placeholder {
            @apply text-body;
        }
    }
    textarea {
        @apply bg-white border-none rounded-xl px-4 py-2 text-body text-lg font-normal;
        &::placeholder {
            @apply text-body;
        }
    }
    // NOTE: previous design with white background, brand blue outline around all items
    // input, select {
    //     @apply border-brand border-2 rounded-full px-4 py-2 text-body text-lg font-normal;
    //     &::placeholder {
    //         @apply text-body;
    //     }
    // }
    // textarea {
    //     @apply border-brand border-2 rounded-xl px-4 py-2 text-body text-lg font-normal;
    //     &::placeholder {
    //         @apply text-body;
    //     }
    // }
}