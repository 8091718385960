#product-results {
    @screen lg {
        .product-search-filters-and-results {
            grid-template-columns: .33fr .66fr;
        }
    }
    .product-search-wrapper {
        .toggle-open-symbol {
            &::after {
                height: 1px;
                width: 9px;
                top: 1px;
            }
        }
    }
    #product-search-filters {
        .toggle-open-symbol {
            &::after {
                height: 1px;
                width: 9px;
                top: 1px;
            }
        }

    }
}

// NOTE: Pulled outside of parent b/c used in search results as well
.product-search-results-grid {
    // Need this to ensure that the 'see all' products load more btn doesn't offset the results
    // display within the grid.
    
    transition-property: height;
    transition-duration: .75s;
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
    height: 100%;
    &.industry-page {
        grid-column: 2/3;
    }
    &.more-results_hidden {
        height: 0;
        overflow: hidden;
    }
    .product-result-item {
        aspect-ratio: 3/2;
        
        picture {
            grid-column: 1/2;
            grid-row: 1/2;
        }
        .product-result-item-title {
            grid-column: 1/2;
            grid-row: 1/2;
            &::after {
                content: '';
                @apply h-full w-full inset-0 absolute -z-[1];
                @apply bg-brand opacity-75;
            }
        }
        @media (hover:hover) {
            &:hover {
                picture {
                    img {
                        transform: scale(1.1);
                    }
                }
                .product-result-item-title {
                    opacity: 1;
                }
            }
        }
    }
}

button#reveal-all-products {
    grid-column: 2/3;
}
