.related-products-container {        
    // @screen md {
        &::after {
            content: "";
            @apply absolute left-0 right-0 bottom-0 top-[45%] w-full bg-brand -z-10;
            @screen md {
                @apply top-[40%];
            }
        }
    // }
    // [data-block-name="related_products"] {

    //     [data-block-name="related_product"] {
    //         &::after {
    //             content: "";
    //             @apply absolute left-0 right-0 bottom-0 w-full bg-brand -z-10;
    //             top: 45%;
    //             @screen md {
    //                 @apply top-[45%];
    //             }
    //             @screen md {
    //                 content: none;
    //             }
    //         }
    //     }
    // }
}
