nav#main-nav {
  grid-template-rows: min-content 1fr;
  #topNavBar {
    &.fullyOpen {
      #nav-logo {
        height: 50px;
      }
    }
    #toggle-search-btn {
      img {
        @apply duration-300;
        transition-property: opacity;
      }
      &.revealed {
        img {
          opacity: 50%;
        }
      }
    }
    .search-form-container {
      #search-form {
        // @apply absolute opacity-0 -right-64 duration-300;
        transition-property: opacity position z-index;
        z-index: -1;
        input {
          @apply placeholder-white;
        }
        &.revealed {
          @apply opacity-100 right-6 z-10;
        }
        .autoComplete_wrapper {
          #autoComplete_list_1 {
            position: absolute;
            background: white;
            z-index: 10;
            width: 100%;
            margin-left: -1rem; // this is to override the base 1rem margin on all ol, uls set in custom-base-styles
            right: 0;
            margin-bottom: 0;
            padding: 1rem 0;
            li {
              padding-left: .5rem; // to override the padding (left) from custom-base-styles
              padding-right: .5rem;
              font-size: small;
              &:not(:last-child) {
                margin-bottom: .75rem;
              }
              &:hover, &[aria-selected="true"] {
                cursor: pointer;
                @apply bg-brand-muted;
              }
              mark {
                @apply font-semibold bg-transparent; 
              } 
            } 
          }
        }
      }
    }
    #nav-logo {
      height: 40px;
    }
    #toggle-nav-btn {
      * {
        transition-property: translate, width;
        @apply duration-200 ease-in-out;
      }
      &.open {
        &::before {
          transform: translateY(0) rotate(45deg);
        }
        .middle-line {
          width:0;
        }
        &::after {
          transform: translateY(0) rotate(-45deg);
        }
      }
      &::before {
        @apply duration-200 ease-in-out;
        content: "";
        position: absolute;
        width: 28px;
        height: 2px;
        background-color: white;
        transform: translateY(-8px);
      }
      &::after {
        @apply duration-200 ease-in-out;
        content: "";
        position: absolute;
        width: 28px;
        height: 2px;
        background-color: white;
        transform: translateY(8px);
      }
    }
  }
  #nav-menu {
    background-color: rgba(255, 255, 255, 0.809);
    right: -100%;
    &.open {
      right: 0;
    }
    #nav-menu-inner {
      button.ac-trigger {
        grid-template-columns: min-content min-content;
        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A") !important;
          position: relative;
          display: inline-block;
          width: auto;
          transform: translate(0,0);
          transform-origin: center;
          inset: 0;
          transition-property: transform;
          transition-timing-function:cubic-bezier(0.075, 0.82, 0.165, 1);
          transition-duration: 300ms;;
        }
        &:hover {
          &::after {
            filter: $brandLightFilter;
          }
        }
        &:active, &:focus {
          &::after {
            filter: $brandDarkFilter;
          }
        }
        &[aria-expanded="true"] {
          &::after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='13' viewBox='0 0 9.2 13'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
            transform: translate(7px,0) rotate(90deg);
          }
        }
      }
    }
    // .isParentNavItem {
    //   > button {
    //     &::after {
    //       transition-property: transform, filter;
    //       line-height: 1;
    //       transform-box: fill-box;
    //       @apply duration-200;
    //       content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 auto' height='100%' %3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M6.5,0,13,9.2H0Z' transform='translate(9.2) rotate(90)' fill='%23497e91'/%3E%3C/svg%3E%0A");
    //       max-height: 10px;
    //       width: 10px;
    //     }
    //     &:hover {
    //       &::after {
    //         filter: $brandLightFilter;
    //       }
    //     }
    //     &:active, &:focus {
    //       &::after {
    //         filter: $brandDarkFilter;
    //       }
    //     }
    //   }
    //   &.open {
    //     button {
    //       &::after {
    //         @apply rotate-90;
    //       }
    //     }
    //   }
    // }
  }
}

nav#anchor-bar-nav {
  @apply duration-500; 
  &.open {
    bottom: 5rem !important;
  }  
}
