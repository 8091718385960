#resource-results {
    @screen lg {
        .resource-search-filters-and-results {
            grid-template-columns: .33fr .66fr;
        }
    }
    .resource-search-wrapper {
        .toggle-open-symbol {
            &::after {
                height: 1px;
                width: 9px;
                top: 1px;
            }
        }
    }
    #resource-search-filters {
        .toggle-open-symbol {
            &::after {
                height: 1px;
                width: 9px;
                top: 1px;
            }
        }

    }
}

// NOTE: Pulled outside of parent b/c used in search results as well
.resource-search-results-grid {
    .resource-result-item {
        aspect-ratio: 3/2;
        transition-duration: 300ms;
        transition-property: transform;
        .resource-result-item-title {
            grid-column: 1/2;
            grid-row: 1/2;
            &::after {
                content: '';
                @apply h-full w-full inset-0 absolute -z-[1];
                @apply bg-brand;
            }
        }
        @media (hover:hover) {
            &:hover {
                transform: translateY(-0.25rem);
            }
        }
    }
}
